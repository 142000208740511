@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
html {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
}
.body{
    margin: 0;
    padding:0;
}
/*body {
    background: #000;
    overflow-x: hidden;*/ /* Hide horizontal scrollbar */
/*}*/

    html::-webkit-scrollbar {
        display: none;
    }

@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url('fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600; 
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

.App {
    text-align: center;
    position: relative;
    
    z-index: 2;
}

li {
    font-family: "Nunito-SemiBold", sans-serif;
    color: white;
}

h3 {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
}
h4 {
    color: white;
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
}
button{

}

p {
    font-family: "Nunito-SemiBold", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    color: white;
}

.container {
    position: relative;
    background-image: url('./Pages/images/welleothemebg.png');
    background-color: #161516;
    padding: 0px;
    z-index: 1;
    background-size: cover;
    height: auto;
    min-height: 100vh;
    overflow-y: hidden;
}


.overflow-visible {
    overflow-y: visible;
}

.content {
    /*background-image: url('./Pages/images/webbg.png');*/
    background-size: cover;
    height: 100%;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient( to top right, rgba(255, 130, 41, 0.05) 0%,
    rgba(255, 215, 43, 0.002) 25%,
    transparent 50%,
    rgba(255, 215, 43, 0.002) 75%,
    rgba(255, 130, 41, 0.0001) 100% 
    );
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

a {
    margin: 0px 10px 0px 10px;
    text-decoration: none;
    text-decoration-color: none;
    text-decoration-style: none;
}

p {
    font-size: 15px;
}

h1 {
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 42px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
h2 {
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
    color: white;
}
.BodyH1 {
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 42px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: 50%;
}
.BodyH12 {
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 42px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: 50%;
}
.BodyH11 {
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 42px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: 100%;
}
.Body2P2 {
    width: 45%;
    color: white;
    font-size: 30px;
}

h3 {
    font-size: 22px;
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.TWA, .WAW, .WRW, .TWA4 {
    color: #A6A0A6;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    font-family: 'Montserrat-SemiBold';
    font-size: 12px;
    padding: 12px;
    display: flex;
    align-items: center; 
    position: relative; 
}

.HeaderWrapper {
    margin: 10px;
}

.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(31, 30, 31, 0.75);
    align-items: center;
    color: white;
    border-radius: 15px;
    position: relative;
    width: 100%;
    top: 0;
}
.HomePage {
    padding-top: 20px;
}
/* Style for active link */
.Header a.active {
    color: white; /* White color for active link */
}

.headerDiv1 {
    margin: 10px;
    width: 55%;
    display: flex;
    justify-content: flex-start;
    border-radius: 15px;
    align-items: center;
}
.headerDiv3 {
    margin: 15px;
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: flex-end;
    align-items: center;
}
.headerDiv2 {
    margin: 15px;
    width: 100%;
    border-radius: 15px;
    /*background-color: rgba(31, 30, 31, 0.75);*/
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
    .headerDiv1 img {
        width: 50%;
        height: auto;
        margin: 0px 0px 0px 5px;
    }


.SignUpButton {
    padding: 0px 15px 0px 15px;
    color: #282c34;
    padding: 15px 25px 15px 25px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    border: 0px;
    border-radius: 10px;
    font-family: "Montserrat-BoldItalic";
    font-size: 16px;
}
.buttonSign {
    margin-top: 15px;
    /*    width: 66%; */
}
/*Body 1*/
.Body1 {
    display: flex;
    position: fixed; /* Change position to fixed */
    z-index: 3;
    margin: 225px 20px 0px 50px;
}

.div1-Body1 {
    text-align: start;
    align-content: start;
    max-width: 50%;
    flex: 1;
}

.div2-Body1 {
    text-align: start;
    align-content: start;
    max-width: 50%;
    flex: 1;
}

.Body1P {
    color: white;
    font-size: 30px;
}

/*Body 2*/
.Body2 {
    display: flex;
    position: fixed;
    z-index: 3;
    margin: 225px 25px 0px 50px;
    overflow-x: hidden;
}

.div1-Body2 {
    text-align: start;
    align-content: start;
    flex: 1;
}
.div1-content-Body1 {
    /*transition: opacity 0.5s ease-in-out;*/
}

.div1-content-Body2 {
    /*transition: opacity 0.5s ease-in-out;*/
    display: flex;
    flex-direction: column;
    text-align: left;
}
.MobileSliderDiv{
    display: none;
}

.CheckBoxDiv {
    width: 500px;
}
.InputButton {
    display: block;
    flex-direction: column;
    z-index: 4;
}

.Input-Body2 {
    color: #ffffff;
    font-size: 16px;
    padding: 15px 25px;
    background: #2A282A;
    border: none;
    border-radius: 10px;
    width: 450px;
    
    margin-bottom: 10px;
}
label {
    color: white;
    margin-bottom: 10px;
}
#checkbox {
    margin-bottom: 10px;
}

.NyhetGDPR {
    text-decoration: none;
    color: navy; 
}

    
    .NyhetGDPR:visited {
        color: navy; 
    }

.HomeSignUpButton {
    color: #282c34;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    width: 500px;
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    padding: 15px 25px;
}

.div1-content-Body2.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.div2-Body2 {
    text-align: end;
    align-content: end;
    max-width: 50%;
    flex: 1;
}

.Body2P {
    color: white;
    font-size: 30px;
    width: 90%;
}
.Image0009 {
    align-content: start;
    width: 50%;
}
.Image{
    width: 75%;
}
.H12, 
.2P {
    text-align: left;
    margin-left: 0;
}
Content1 {
    text-align: start;
    align-content: start;
    flex: 1;
}
Content2 {
    text-align: end;
    align-content: end;
    flex: 1;
}
.fade-out {
    animation: fade-out 3s;
}

.opacityLow {
    animation: opacityLow 1s;
}
.LogoHomePage {
    width: 100%;
}

@keyframes opacity-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }

    to {
        opacity: 100;
        transform: translateX(0);
    }
}

@keyframes fade-out {
    from {
        opacity: 100;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(200px);
    }
}

@keyframes opacityLow {
    from {
        opacity: 100;
    }

    to {
        opacity: 0;
    }
}

@media (max-width: 575.98px) {
    .TWA4{
        width: 100%;
    }
    .HomePage2 {
        display: none;
    }
    .MobileSliderDiv{
        display: flex;
    }
    .BodyH1 {
        width: 100%;
    }
    .BodyH12 {
        width: 100%;
    }
    .HamburgerButton {
        z-index: 5;
        padding: 10px; 
        font-size: 24px; 
        cursor: pointer;
    }
    .Body1P,
    .Body2P2{
        font-size: 18px;
        margin: 10px 10px 0px 10px;
    }
    h1 {
        font-size: 25px;
        margin: 10px 10px 0px 10px;
    }
    .Image0009 {
    }
    .Body1,
    .Body2 {
        display: none;
        flex-direction: column;
        margin-top: 0;
        text-align: center;
    }
    .div1-content-Body1,
    .div1-content-Body2 {
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .div1-Body1,
    .div2-Body1,
    .div1-Body2,
    .div2-Body2 {
        max-width: 100%;
        flex: none;
        margin: 0;
        text-align: center;
    }
    .WRW,
    .WAW,
    .TWA {
        display: none;
    }
    .TWA2,
    .WAW2,
    .WRW2 {
        width: 100%;
        font-size: 10px;
    }
    .SignUpButton {
        padding: 12px;
        font-size: 12px;
    }
    .headerDiv1 img {
        width: 200%;
        z-index: 20;
    }
    .overlay2 a {
        font-size: 14px;
        width: 50%;
    }

}

@media (min-width: 576px) and (max-width: 767.98px) {
    .MobileSliderDiv {
        display: flex;
    }
    .overlay2 a {
        font-size: 10px;
    }
    .HomePage2 {
        display: none;
    }

    .TWA2,
    .WAW2,
    .WRW2 {
        width: 100%;
    }
    .HamburgerButton {
        z-index: 5;
        padding: 10px; 
        font-size: 24px; 
        cursor: pointer;
    }
    .Body1P,
    .Body2P2 {
        font-size: 18px;
        margin: 10px 10px 0px 10px;
    }

    .BodyH1 {
        width: 100%;
    }

    .BodyH12 {
        width: 100%;
    }
    h1 {
        font-size: 25px;
        margin: 10px 10px 0px 10px;
    }


    .Image0009 {
        margin-top: -30px;
        align-content: start;
        width: 50%;
    }

    .Body1,
    .Body2 {
        display: none;
        flex-direction: column;
        margin-top: 0;
        text-align: center;
    }

    .div1-content-Body1,
    .div1-content-Body2 {
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .div1-Body1,
    .div2-Body1,
    .div1-Body2,
    .div2-Body2 {
        max-width: 100%;
        flex: none;
        margin: 0;
        text-align: center;
    }
    .WRW,
    .WAW,
    .TWA {
        display: none;
    }

    .SignUpButton {
        padding: 8px;
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .MobileSliderDiv {
        display: none;
    }
    .Image0009 {
        align-content: start;
        margin-top: -20px;
        width: 60%;
        margin-right: 75px;
    }
    .Body2, .Body1 {
        margin-top: 50px;
        display: flex;
        width: 100%;
        margin: 50px 25px 0px 20px;
    }
    .BodyH12 {
        width: 100%;
        font-size: 25px;
    }
    .WRW,
    .WAW,
    .TWA {
        display: none;
    }
    .BodyH12 {
        width: 100%;
        font-size: 25px;
    }
    .BodyH11 {
        width: 75%;
        font-size: 25px;
    }

    .Body2P2, .Body1P {
        width: 75%;
        color: white;
        font-size: 15px;
    }
    .div1-Body1, .div1-Body2 {
        flex: 1;
        text-align: left;
        margin-top: 15px;
        margin-left: 75px;
    }
    .HamburgerButton {
        z-index: 5;
        padding: 10px; /* Adjust padding as needed */
        font-size: 24px; /* Adjust font size as needed */
        cursor: pointer;
    }
    .SignUpButton {
        padding: 8px;
        font-size: 12px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

    .Image0009 {
        margin-top: -20px;
        align-content: start;
        width: 60%;
        margin-right: 75px;
    }
    .Body2, .Body1 {
        margin-top: 50px;
        display: flex;
        width: 100%;
        margin: 0px 25px 0px 20px;
    }
    .div1-Body1, .div1-Body2 {
        flex: 1;
        width: 100%;
        text-align: left;
        margin-left: 75px;
        margin-top: 30px;
    }
    .BodyH12 {
        width: 75%;
        font-size: 30px;
    }

    .BodyH11 {
        width: 75%;
        font-size: 30px;
    }

    .Body2P2, .Body1P {
        width: 75%;
        color: white;
        font-size: 20px;
    }

    .div1-Body1, .div1-Body2 {
        flex: 1;
        text-align: left;
    }
    .HamburgerButton {
        display: none;
    }

    .headerDiv2 {
        width: 100%;
    }
    .TWA,.WAW, .WRW {
        font-size: 8px;
    }
    .SignUpButton {
        font-size: 13px; 
    }
}

@media (min-width: 1200px) and (max-width: 1439.98px) {

    .Image0009 {
        margin-top: -20px;
        align-content: start;
        width: 55%;
        margin-right: 75px;
    }

    .Body2, .Body1 {
        margin-top: 50px;
        display: flex;
        width: 100%;
        margin: 0px 25px 0px 20px;
    }

    .div1-Body1, .div1-Body2 {
        flex: 1;
        width: 100%;
        text-align: left;
        margin-left: 75px;
        margin-top: 30px;
    }

    .BodyH12 {
        width: 75%;
        font-size: 30px;
    }

    .BodyH11 {
        width: 75%;
        font-size: 30px;
    }

    .Body2P2, .Body1P {
        width: 75%;
        color: white;
        font-size: 20px;
    }

    .div1-Body1, .div1-Body2 {
        flex: 1;
        text-align: left;
    }

    .HamburgerButton {
        display: none;
    }

    .headerDiv2 {
        width: 100%;
    }

    .TWA, .WAW, .WRW {
        font-size: 8px;
    }

    .SignUpButton {
        font-size: 13px; 
    }
}

@media (min-width: 1440px) and (max-width: 1599.98px) {

    .Image0009 {
        margin-top: -20px;
        align-content: start;
        width: 50%;
        margin-right: 50px;
    }
    .div1-Body1, .div1-Body2 {
        flex: 1;
        width: 100%;
        text-align: left;
        margin-top: 15px;
        margin-left: 50px;
    }
    
    .Body1,
    .Body2 {
        margin: 0px 25px 0px 50px;
    }

    .HamburgerButton {
        display: none;
    }

    .BodyH1 {
        width: 75%;
        font-size: 35px;
    }

    .Body1P,
    .Body2P2 {
        width: 75%;
        font-size: 25px;
    }


    .BodyH12 {
        width: 75%;
        font-size: 35px;
    }
}

@media (min-width: 1600px) and (max-width: 1919.98px) {

    .Image0009 {
        align-content: start;
        margin-top: -20px;
        width: 55%;
    }

    .div1-Body1, .div1-Body2 {
        flex: 1;
        width: 100%;
        text-align: left;
        margin-left: 75px;
        margin-top: 15px;
    }

    .div2-Body2 {
        flex: 1;
        width: 100%;
        margin-right: 10%;
    }

    .BodyH12 {
        width: 75%;
    }

    .BodyH11 {
        width: 60%;
    }

    .Body2P2 {
        width: 75%;
    }

    .Body1P {
        width: 50%;
    }

    .Body2, .Body1 {
        margin-top: 0px;
        display: flex;
        width: 100%;
        justify-content: stretch;
        align-items: stretch;
    }

    .HamburgerButton {
        display: none;
    }
}

@media (min-width: 1920px) and (max-width: 1999.98px) {

    .Image0009 {
        margin-top: 0px;
        margin-top: -20px;
        align-content: start;
        width: 55%;
    }
    .div1-Body1, .div1-Body2 {
        flex: 1;
        width: 100%;
        text-align: left;
        margin-top: 15px;
        margin-left: 75px;
    }
    .div2-Body2 {
        flex: 1;
        width: 100%;
        margin-right: 10%;
    }
    .headerDiv1 img {
        width: 40%; 
    }
    .BodyH12 {
        width: 60%;
    }

    .BodyH11 {
        width: 60%;
    }

    .Body2P2 {
        width: 75%;
    }

    .Body1P {
        width: 50%;
    }
    .Body2, .Body1 {
        margin-top: 0px;
        display: flex;
        width: 100%;
        justify-content: stretch;
        align-items: stretch;
    }

    .HamburgerButton {
        display: none;
    }
}
@media screen and (min-width: 2000px) and (max-width: 2559.98px) {

    .Body2, .Body1 {
        display: flex;
        width: 100%;
        margin-top: 0px;

    }

    .div1-Body1, .div1-Body2 {
        flex: 1;
        width: 75%;
        text-align: left;
        margin-top: 15px;
        margin-left: 10%;
    }

    .div2-Body2 {
        text-align: center; 
    }

    .Image0009 {
        margin-top: -0px;
        align-self: flex-start;
        width: 50%;
    }

    .HamburgerButton {
        display: none;
    }
}
@media screen and (min-width: 2560px) and (max-width: 4000px) {
    .Header a, .Header .TagTitle {
        font-size: 24px;
    }
    .Header .tag{
        font-size: 12px;
    }

    .SignUpButton {
        font-size: 24px; 
    }

    .headerDiv1 img {
        width: 60%; 
    }

    .TagTitle {
        font-size: 1.2rem; 
    }

    .Body2, .Body1 {
        display: flex;
        width: 100%;
        margin-top: 0px;
        justify-content: stretch;
        align-items: stretch;
    }

    .div1-Body1, .div1-Body2 {
        flex: 1;
        width: 75%;
        margin-top: 15px;
        text-align: left;
        margin-left: 10%;
    }

    .div2-Body2 {
        text-align: center;
    }

    .Image0009 {
        margin-top: -50px;
        align-self: flex-start;
        width: 50%;
    }

    .HamburgerButton {
        display: none;
    }
    .BodyH12 {
        width: 50%;
        font-size: 62px;
    }

    .BodyH11 {
        width: 50%;
        font-size: 62px;
    }
    .Body2P2 {
        width: 60%;
        font-size: 40px;
    }
    .Body1P {
        width: 60%;
        font-size: 40px;
    }
}


    .overlay2 {
        height: 0;
        width: 100%;
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 1);
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        transition: 0.5s;
    }

    .overlay2-content {
        position: relative;
        top: 13%;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


.TagTitle-container {
    position: relative; 
    display: inline-block;
}

.TagTitle {
}

.tag {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    font-family: 'Montserrat-SemiBold';
    color: black;
    font-size: 0.6rem;
    padding: 0.2em 0.5em;
    border-radius: 15px;
    position: absolute;
    margin-top: -10px;
    z-index: 1;
}
.TWA2,
.WAW2,
.WRW2 {
    color: #A6A0A6;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    width: 35%;
    border-radius: 15px;
    border: 1px solid white;
    font-family: 'Montserrat-SemiBold';
    padding: 12px;
}

    .SignUpButton2 {
        padding: 0px 15px 0px 15px;
        color: #282c34;
        padding: 15px 25px 15px 25px;
        background: linear-gradient(to right, #FFD72B, #FF8229);
        border-radius: 10px;
        margin: 15px;
        border: none;
        box-shadow: none;
        text-decoration: none;
        font-family: Arial, sans-serif;
        font-weight: 600;
        font-style: italic;
        font-size: 12px;
    }

    .overlay2 a {
        padding: 40px;
        text-decoration: none;
        display: block;
        transition: 0.3s;
        margin-bottom: 15px;
    }

        .overlay2 a:hover, .overlay2 a:focus {
            color: #f1f1f1;
        }

    .overlay2 .closebtn {
        position: absolute;
        color: white;
        top: 10px;
        right: 15px;
        font-size: 60px;
        background-color: black;
        box-shadow: none;
        border: none;
    }

    .HamburgerButton {
        z-index: 5;
    }

.ResponseMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.message {
    font-size: 14px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
}

    .message.success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        width: 63%;
        margin-top: 15px;
    }

    .message.error {
        color: #721c24;
        margin-top: 15px;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        width: 63%;
    }