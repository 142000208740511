.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding-bottom: 20px;
    width: 100%;
    background-color: transparent; /* Set the background color to transparent */
    color: white; /* Example text color */
    text-align: center;
    z-index: 3; /* Ensure the footer is above other content */
    display: flex; /* Add display flex */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Align items horizontally */
}

.ScrollToFind {
    width: 200px;
}

@media (max-width: 767.98px) {
    .ScrollToFind {
        width: 150px;
        margin: 10px;
        display: none;
    }
}
