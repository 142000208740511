.ContactBody {
    display: flex;
    margin: 0;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    position: relative;
    z-index: 3;
    left: 0;
    right: 0;
}

    .ContactForm div {
        display: flex;
        flex-direction: column;
    }

    .ContactForm label {
        margin-bottom: 0.5rem;
    }

    .ContactForm input,
    .ContactForm textarea {
        color: #ffffff;
        font-size: 16px;
        padding: 15px 25px;
        background: #2A282A;
        border: none;
        border-radius: 10px;
        width: 60%;
        margin-bottom: 10px;
    }

    .ContactForm button {
        padding: 0.75rem;
        font-size: 1rem;
        cursor: pointer;
    }
input, textarea {
    pointer-events: auto; /* Make sure pointer-events are not set to none */
    /* Check for any visibility or opacity issues */
}
