.EntireContainer {
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    scroll-snap-type: y mandatory;
    width: 100%;
    z-index: 5;
}

.slider-container {
    text-align: center;
    width: 85%;
    margin: auto;
    margin-top: -20px;
    position: relative;
    z-index: 1;
    border-radius: 15px;
}

.slider-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dots-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.InputMobile {
    color: #ffffff;
    font-size: 16px;
    padding: 15px 25px;
    background: #2A282A;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
}

.Gif {
    padding-bottom: 20px;
    width: 100%;
    background-color: transparent; 
    color: white;
    text-align: center;
    z-index: 3; 
    align-items: center; 
    justify-content: center; 
}

.CheckDiv {
}

.ScrollToFind2 {
    width: 200px;
}
.ImageMobile {
    width: 100%;
    height: auto;
    margin-top: 30px;
}
.image-title {
    padding: 0;
    margin: 0;
}
.Image2 {
    width: 200px;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: gray;
    margin: 10px;
    cursor: pointer;
}

    .dot.active {
        background-color: white;
    }

.slide {
    display: none;
    width: 100%;
}

    .slide.active {
        display: flex;
        align-items: center;
    }

.image-title, .image-label {
    text-align: center;
    padding: 10px 0;
}

.slider-content img {
    width: 100%;
}

.slide {
    display: none;
}

    .slide.active {
        display: block;
    }

.image-label {
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    min-height: 25px;
    max-height: 25px;
    font-size: 14px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.Scroll {
    width: 200px;
    margin: 10px;
}

.image-title {
    font-size: 20px;
    min-height: 25px;
    max-height: 25px;
    padding: 20px;
}

.ContactDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 325px;
    margin-bottom: 325px;
    gap: 20px;
    width: 100%;
}

.colored-text {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.white-text {
    color: white;
}

.ContactDiv1, .ContactDiv2 {
    margin-right: 20px;
}


.MobileSignUp {
    margin-top: 250px;
    margin-bottom: 50px;
}

@media (min-width: 600px) and (max-width: 767px) {
    .slider-container {
        width: 55%;
    }

}
    @media (min-width: 768px) and (max-width: 991.98px) {
        .slider-container {
            width: 75%;
        }

        .image-label {
            color: white;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
            min-height: 25px;
            max-height: 25px;
        }

        .image-title {
            font-size: 20px;
            min-height: 25px;
            max-height: 25px;
            padding: 0px;
        }

        .ImageMobile {
            margin-top: -10px;
        }

        .MobileSignUp {
            margin-top: 250px;
            margin-bottom: 150px;
        }
    }

    .ResponseMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .message {
        font-size: 14px;
        padding: 10px;
        margin-top: 10px;
        border-radius: 5px;
        text-align: center;
    }

        .message.success {
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;
            width: 63%;
            margin-top: 15px;
        }

        .message.error {
            color: #721c24;
            margin-top: 15px;
            background-color: #f8d7da;
            border-color: #f5c6cb;
            width: 63%;
        }
