@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


.footer3 {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    box-sizing: border-box;
    border: none;
    z-index: 1000;
}

.footer-content3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 1000;
}

.footer-left2 {
    color: white;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 12px;
    font-family: "Nunito-SemiBold", sans-serif;
}

.footer-right2 {
    color: rgba(255, 255, 255, 0.4);
    text-align: right;
    font-size: 12px;
    font-family: "Nunito-SemiBold", sans-serif;

}

.Icons2 {
    display: flex;
    flex-direction: row;
    width: 25%;
}
.logos2 {
    color: white;
    font-size: 20px;
    border-radius: 15px;
    padding: 0;
    margin: 0 5px; /* Reduce margin between logos */
    width: 100%;
}

.FooterLinks2 {
    padding: 0;
    margin: 0 5px; /* Reduce margin between links */
}
@media (max-width: 768px) {
    .footer3 {
        display: none;
    }
    .footer-right2 {
        font-size: 10px;
    }

    .footer-left2 {
        font-size: 10px;
    }

    .logos2 {
        font-size: 12px;
    }

    .Icons2 {
        display: flex;
        flex-direction: row;
        width: 35px;
    }
}

@media (min-width: 768px) and (max-width: 1440px) {

    .footer-right2 {
        font-size: 10px;
    }

    .footer-left2 {
        font-size: 10px;
    }

    .logos2 {
        font-size: 12px;
    }

    .Icons2 {
        display: flex;
        flex-direction: row;
        width: 35px;
    }
}

@media (min-width: 1440px) and (max-width: 1920px) {
    .footer-right2 {
        font-size: 12px;
    }

    .footer-left2 {
        font-size: 16px;
    }

    .logos2 {
        font-size: 20px;
    }

    .Icons2 {
        display: flex;
        flex-direction: row;
        width: 35px;
    }
}

@media (min-width: 1921px) and (max-width: 2600px) {
    .footer-right2 {
        font-size: 20px;
    }

    .footer-left2 {
        font-size: 20px;
    }

    .logos2 {
        font-size: 40px;
    }

    .Icons2 {
        display: flex;
        flex-direction: row;
        width: 50px;
    }
}